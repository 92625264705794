<template>
  <div class="profile__wrapper">
    <h4 class="title-h4 profile__title">{{ $t('mail_form.title') }}</h4>
    <div class="profile__block">
      <!-- Поле ввода темы письма -->
      <TextField
        id="mail-subject"
        v-model="mail.subject"
        :placeholder="$t('mail_form.subject_placeholder')"
      />
      <span v-if="errors.subject" class="error-message">
        {{ $t('mail_form.errors.subject_required') }}
      </span>

      <!-- Поле ввода текста письма -->
      <TextEditor
        id="mail-body"
        v-model="mail.body"
        :placeholder="$t('mail_form.body_placeholder')"
      />
      <span v-if="errors.body" class="error-message">
        {{ $t('mail_form.errors.body_required') }}
      </span>

      <!-- Чекбоксы для выбора группы получателей -->
      <div class="mail-form__checkboxes">
        <label class="checkbox-label">
          <Checkbox
            v-model="mail.recipients.tourOperators"
          />
          {{ $t('mail_form.recipients.tourOperators') }}
        </label>
        <label class="checkbox-label">
          <Checkbox
            v-model="mail.recipients.hotels"
          />
          {{ $t('mail_form.recipients.hotels') }}
        </label>
        <label class="checkbox-label">
          <Checkbox
            v-model="mail.recipients.certificationBodies"
          />
          {{ $t('mail_form.recipients.certificationBodies') }}
        </label>
      </div>

      <!-- Кнопка отправки -->
      <Button class="send__btn" @click.native="validateAndSend">
        {{ $t('mail_form.send_button') }}
      </Button>
    </div>
  </div>
</template>

<script>
import { sendMail } from '@/api/services/dart';
import TextEditor from "../../elements/TextEditor.vue";

export default {
  components: { TextEditor },
  data() {
    return {
      mail: {
        subject: '',
        body: '',
        recipients: {
          tourOperators: false,
          hotels: false,
          certificationBodies: false,
        },
      },
      errors: {
        subject: false,
        body: false,
      },
    };
  },
  methods: {
    async validateAndSend() {
      // Сброс ошибок
      this.errors.subject = !this.mail.subject.trim();
      this.errors.body = !this.mail.body.trim();

      if (this.errors.subject || this.errors.body) {
        return;
      }

      // Формирование данных для отправки
      const mailData = {
        subject: this.mail.subject,
        body: this.mail.body,
        recipients: {
          tourOperators: this.mail.recipients.tourOperators,
          hotels: this.mail.recipients.hotels,
          certificationBodies: this.mail.recipients.certificationBodies,
        },
      };

      try {
        const result = await sendMail(mailData);
        console.log('Mail sent successfully:', result);
        this.$toast.success(this.$t('mail_form.success_message'));
      } catch (error) {
        console.error('Failed to send mail:', error);
        this.$toast.error(this.$t('mail_form.error_message'));
      }
    },
  },
};
</script>

<style scoped>
.error-message {
  color: red;
  font-size: 0.875rem;
  margin-top: 4px;
}
</style>
